import React from "react";
import Seo from "../components/seo";
import MyDashboard from "../components/MY";

const MYDashboardPage = (props) => (
  <>
    <Seo title="MY Projects" />
    <MyDashboard {...props} />
  </>
);

export default MYDashboardPage;
